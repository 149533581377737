.apartment-info-section {
  margin-top: 34px;
}

.apartment-info-section .section-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.apartment-info-section h3 {
  color: var(--color-accent-coral-500);
  max-width: 410px;
}

.body-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--color-primary-700);
}

.apartment-image-links {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.apartment-image-links a {
  width: 100%;
}

.apartment-image-links img {
  width: 100%;
  height: 136px;
  border-radius: 8px;
  object-fit: cover;
}

.apartment-info-link {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--color-accent-coral-500);
  text-decoration: none;
}

@media (min-width: 375px) {
  .apartment-image-links {
    display: flex;
    flex-direction: row;
  }

  .apartment-image-links img {
    height: 160px;
  }
}

@media (min-width: 481px) {
  .apartment-image-links {
    display: flex;
    flex-direction: row;
  }

  .apartment-image-links img {
    height: 160px;
  }
}

@media (min-width: 769px) {
  .apartment-image-links {
    display: flex;
    flex-direction: row;
    width: 80%;
  }

  .apartment-image-links img {
    height: 200px;
  }

  .apartment-info-section {
    margin: 48px 0;
  }
}

@media (min-width: 919px) {
  .apartment-image-links img {
    height: 250px;
  }
}

@media (min-width: 1024px) {
  .apartment-image-links img {
    height: 250px;
  }
}
