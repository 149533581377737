.welcome-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--color-primary-700);
  margin-bottom: 16px;
}

.welcome-section img {
  margin-top: 16px;
  object-fit: cover;
  width: 100%;
  height: 240px;
}

.welcome-section-text-content {
  margin-bottom: 24px;
}

.welcome-section-text-content p {
  margin: 16px 0;
}

.welcome-section h3 {
  margin-top: 16px;
  color: var(--color-accent-coral-500);
}

@media (min-width: 425px) {
  .welcome-section img {
    height: 280px;
    object-fit: cover;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .welcome-section img {
    height: 400px;
  }
}
