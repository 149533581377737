.brand {
  color: var(--color-primary-700);
  font-weight: 500;
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
}

.header-divider {
  height: 2px;
  background-color: var(--color-accent-coral-500);
}

.menu-icon {
  width: 25px;
  height: 20px;
}

.logo {
  width: 44px;
}

@media (min-width: 481px) {
  .header {
    display: flex;
    flex-direction: row-reverse;
  }

  header {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .brand {
    margin-left: auto;
    margin-right: auto;
    justify-self: center;
  }
  .menu-icon {
    display: none;
  }

  .logo {
    width: 54px;
  }
}

@media (min-width: 769px) {
  .logo {
    width: 60px;
    height: 60px;
  }
}
