.month-picker {
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.month-picker.secondary {
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.month-picker > p {
  font-family: "Roboto";
  color: var(--color-primary-700);
  font-weight: 400;
  font-size: 16px;
}

.month-picker img:hover {
  cursor: pointer;
}

.month-picker.secondary img {
  display: none;
}

@media (min-width: 375px) {
  .month-picker {
    width: 72%;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
