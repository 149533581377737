.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary-500);
  position: relative;
}

.step-indicator {
  margin-top: auto;
  margin-bottom: auto;
}

.circle-1::before {
  content: "1";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.circle-2::before {
  content: "2";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.circle-3::before {
  content: "3";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.section-header p:first-of-type {
  color: var(--color-primary-500);
}

.section-header p:nth-of-type(2) {
  color: var(--color-primary-700);
}

.section-header {
  display: flex;
  gap: 8px;
  padding: 8px 8px;
}
