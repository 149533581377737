.reservation-form {
  width: 100%;
  /* height: 770px; */
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* border: solid 1px rgb(167, 167, 167); */
  /* overflow: hidden; */
}

.agree {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 90%;
  margin: 0 auto;
  border-radius: 8px;
  padding: 8px 8px;
  justify-content: flex-start;
  box-shadow: none;
}

.agree input:focus {
  outline: none;
  border: none;
}

.submit {
  vertical-align: middle;
  padding: 16px 0;
  background-color: white;
  /* border: 1px solid var(--color-primary-700); */
  border: none;
  color: white;
  background-color: var(--color-accent-coral-500);
  width: calc(90% + 16px);
  margin: 0 auto;
}

.submit:active {
  transform: translateY(2px);
}

.submit:hover {
  cursor: pointer;
}

.submit:disabled {
  background-color: rgb(83, 83, 83);
  border: none;
  pointer-events: none;
}

@media (min-width: 425px) {
  .submit,
  .agree {
    width: 65%;
  }
}

@media (min-width: 769px) {
  .submit,
  .agree {
    width: 60%;
  }
}

@media (min-width: 897px) {
  .submit,
  .agree {
    width: 50%;
  }
}

@media (min-width: 1025px) {
  .submit,
  .agree {
    width: 45%;
  }
}
