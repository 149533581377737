.reservation-details-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 90%;
  margin: 0 auto;
  border-radius: 8px;
  padding: 8px 8px;
}

input {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 8px 12px;
  flex: 1;
  font-family: "roboto";
  background-color: white;
}

input:focus {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  outline: 1px solid #bfbfbf;
}

@media (min-width: 425px) {
  .reservation-details-container {
    width: 65%;
  }
}

@media (min-width: 769px) {
  .reservation-details-container {
    width: 60%;
  }
}

@media (min-width: 897px) {
  .reservation-details-container {
    width: 50%;
  }
}

@media (min-width: 1025px) {
  .reservation-details-container {
    width: 45%;
  }
}
