.res-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 69.4%;
  gap: 24px;
  height: 48px;
  justify-content: center;
  padding: 8px 16px;
  background-color: var(--color-primary-500);
  border-radius: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

.res-button > p {
  color: white;
}

.res-button > img {
  width: 32px;
  height: 32px;
}

.arrival-departure-button {
  width: 75%;
  background-color: white;
}

.arrival-calendar {
  width: 332;
}

.arrival-departure-form {
  display: flex;
  flex-direction: row;
  width: 62%;
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 50px;
  padding: 8px 8px;
  background-color: white;
  align-items: center;
  z-index: 4;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 16px;
  bottom: -50px;
  max-width: 750px;
}

.booking-button {
  border-radius: 45px;
  background-color: var(--color-accent-coral-500);
  color: white;
  padding: 12px 16px;
}

.booking-button:hover {
  cursor: pointer;
}

.arrival {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.arrival:hover,
.departure:hover {
  cursor: pointer;
}

.arrival img {
  width: 28px;
  height: 28pxpx;
}
