.date-grid {
  display: grid;
  grid-template-rows: repeat(7, 1fr); /* Define 7 rows with equal height */
  grid-template-columns: repeat(7, 1fr); /* Define 7 columns with equal width */
  width: 100%;
  border-radius: 8px;
  /* background-color: var(--date-grid-fill); */
  padding: 16px 8px;
}

.days-of-the-week {
  font-family: "roboto";
  color: var(--days-of-week-fill);
  font-size: 16px;
  text-align: center;
  color: #d5d5d6;
}
@media (max-width: 400px) {
  .days-of-the-week {
    font-family: "roboto";
    color: var(--days-of-week-fill);
    font-size: 10px;
    text-align: center;
    color: #d5d5d6;
  }
}
