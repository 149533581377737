.nav-bar {
  display: none;
}

.nav-bar a {
  text-decoration: none;
  color: var(--color-primary-700);
  padding: 8px 0;
}

.nav-bar a:hover {
  color: var(--color-accent-coral-500);
}

.nav-bar a.active {
  color: var(--color-accent-coral-500);
}

@media (min-width: 481px) {
  .nav-bar {
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 8px 0;
  }
}

@media (min-width: 769px) {
  .nav-bar {
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
