.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary-700-transparent);
  opacity: 100;
  transition: all 0.5s ease-in-out;
  z-index: 3;
}

.show {
  opacity: 100;
}
