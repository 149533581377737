.apartments-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--color-primary-700);
  max-width: 1190px;
}

.apartments-section h3 {
  color: var(--color-accent-coral-500);
  margin: 16px 0;
}

.apartments-section p {
  margin-bottom: 24px;
}

@media (min-width: 625px) {
  .apartment-one {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 24px;
    max-width: 1200px;
  }

  .apartment-two {
    display: flex;
    flex-direction: row-reverse;
    gap: 24px;
    max-width: 1200px;
  }

  .apartment-one p,
  .apartment-two p {
    width: 80%;
  }

  @media (min-width: 1678px) {
    .apartment-one,
    .apartment-two {
      margin: 0 auto;
    }
    .apartments-section {
      margin: 0 auto;
    }
  }
}
