.policies-container {
  color: var(--color-primary-700);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.policies-container section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.policies-container section h3 {
  color: var(--color-accent-coral-500);
}

.policies-container section p {
  color: var(--color-primary-700);
}

.policies-end {
  color: var(--color-accent-coral-500);
}

@media (min-width: 425px) {
  .policies-container {
    width: 90%;
  }
}

@media (min-width: 769px) {
  .policies-container {
    width: 90%;
  }
}

@media (min-width: 897px) {
  .policies-container {
    width: 90%;
  }
}

@media (min-width: 1025px) {
  .policies-container {
    width: 90%;
  }
}
