.carousel-controls {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 0 8px;
}

.carousel-controls img {
  width: 32px;
}

.carousel-controls img:hover {
  cursor: pointer;
}

.center {
  top: 50%;
  transform: translateY(-50%);
}
